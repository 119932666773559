<template>
  <DetailTemplate
    :custom-class="'ticket-detail detail-page'"
    v-if="getPermission('visit:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex align-center flex-wrap" v-if="pageLoaded">
        <span
          class="color-custom-blue font-weight-700 font-size-20 text-truncate mr-2"
          style=""
          >{{ ticket.title }}
        </span>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3"
          style="font-size: 21px !important; font-weight: 700"
          >{{ visit.barcode }}</v-chip
        >
        <Priority :priority="detail.priority"></Priority>

        <CustomStatus
          :status="visit.status"
          endpoint="visit/status"
        ></CustomStatus>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              v-if="visit.status == 7"
              class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
              color="#66bb6a"
              label
            >
              Completed
            </v-chip>
          </template>
          <span>Completed</span>
        </v-tooltip>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mx-2 d-inline-table font-weight-600"
              color="chip-custom-blue"
              label
              outlined
            >
              {{ getScheduleType }}
            </v-chip>
          </template>
          <span>Schedule Type</span>
        </v-tooltip>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              v-if="visit_reschdule_count > 0"
              class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
              color="red"
              label
            >
              Rivision ({{ visit_reschdule_count }})
            </v-chip>
          </template>
          <span>Rivision ({{ visit_reschdule_count }})</span>
        </v-tooltip>

        <v-tooltip
          top
          content-class="custom-top-tooltip"
          v-if="parseInt(visit.follow_up) && follow_up_route != 'follow-up'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mx-2 d-inline-table font-weight-600"
              :color="`${getFollowColor(visit.followup[0].status)} white--text`"
              label
              outlined
            >
              Follow Up
              <template
                v-if="
                  visit && visit.followup[0] && visit.followup[0].status == 2
                "
                >Closed</template
              >
            </v-chip>
          </template>
          <span>Follow Up</span>
        </v-tooltip>
      </div>
    </template>

    <template v-slot:header-action>
      <div class="d-flex align-center justify-end flex-wrap" v-if="pageLoaded">
        <template
          v-if="
            checkStartTimeButton() &&
            !is_staff &&
            visit.status != 4 &&
            visit.status != 5 &&
            assigned_team.length > 0
          "
        >
          <template v-if="!runningTimer.id">
            <v-btn
              v-if="visit.status == 3 || visit.status == 6"
              class="mx-2 custom-bold-button white--text mb-1"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
              v-on:click="resumeTimerDialog = true"
            >
              <!-- <v-icon left>mdi-clock-alert-outline</v-icon> -->
              Resume Timer
              <!--  CheckIn -->
            </v-btn>
            <v-btn
              v-else
              class="mx-2 custom-bold-button white--text mb-1"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
              v-on:click="startTimer"
            >
              <v-icon left>mdi-clock-outline</v-icon>
              Start Timer
            </v-btn>
          </template>
          <template v-else>
            <v-tooltip
              top
              content-class="custom-top-tooltip"
              v-if="checkout.checkin_time"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 mb-1 custom-bold-button white--text"
                  color="cyan"
                  :disabled="timerLoading || visitLoading"
                  :loading="timerLoading"
                  v-on:click="assignServiceFormDetail"
                >
                  Service Form
                </v-btn>
              </template>
              <span>Click here to assign service form</span>
            </v-tooltip>
            <template v-if="checkout && checkout.id != null">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="VisitChecoutUpdate"
                  >
                    CheckOut
                  </v-btn>
                </template>
                <span>CheckOut</span>
              </v-tooltip>
            </template>
            <template v-if="checkout && checkout.id != null">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="HoldandStayandCheckoutDialog = true"
                  >
                    Hold
                  </v-btn>
                </template>
                <span>Hold</span>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="VisitCheckinUpdate"
                  >
                    CheckIn
                  </v-btn>
                </template>
                <span>CheckIn</span>
              </v-tooltip>
            </template>
            <template v-if="!runningTimer.closer && checkout.checkin_time">
              <!-- runningTimer.is_service_form_filled && -->
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2 mb-1 custom-bold-button white--text"
                    color="cyan"
                    :disabled="timerLoading || visitLoading"
                    :loading="timerLoading"
                    v-on:click="closerDialog = true"
                  >
                    STOP
                  </v-btn>
                </template>
                <span>Click here to create closer</span>
              </v-tooltip>
            </template>
            <v-btn
              class="mx-2 mb-1 custom-bold-button white--text"
              color="cyan"
              :disabled="timerLoading || visitLoading"
              :loading="timerLoading"
            >
              {{ timerDuration.hour }}<span class="blink-me">:</span
              >{{ timerDuration.minute }}<span class="blink-me">:</span
              >{{ timerDuration.second }}
            </v-btn>
          </template>
        </template>

        <template v-if="follow_up_route == 'follow-up'">
          <v-chip
            class="mx-2 d-inline-table font-weight-600"
            :color="`${getFollowColor(visit.followup[0].status)} white--text`"
            label
            outlined
            v-on:click="closeFollowUp(visit.followup[0])"
          >
            Follow Up
            <template
              v-if="visit && visit.followup[0] && visit.followup[0].status == 2"
              >Closed</template
            >
          </v-chip>
        </template>
        <template>
          <v-btn
            v-if="follow_up_route != 'follow-up'"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="share_dialog = true"
          >
            <v-icon left>mdi-share</v-icon>
            Share Service Report
          </v-btn>
        </template>
        <template v-if="visit.status == 1">
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="routeToUpdate()"
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }" v-if="visit.status != 7 && follow_up_route != 'follow-up'">
            <v-btn
              :disabled="statusLoading"
              class="mx-2 mb-1 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                v-if="more.action"
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>

            <!-- <template v-if="closerdata.accept_term == 0 && is_staff">
              <v-list-item link v-on:click="getSignatureLink()" :key="2">
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">mdi-signature-freehand</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">
                  Signature Link
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="closerdata.accept_term == 0 && is_staff">
              <v-list-item link v-on:click="mail_dialog = true" :key="3">
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">mdi-email-send</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">
                  Send as Email
                </v-list-item-title>
              </v-list-item>
            </template>  -->
          </v-list>
        </v-menu>
        <template v-if="engineerList.length">
          <v-menu
            v-model="engineer_menu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Team
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in engineerList"
                  :key="index"
                >
                  <!-- <v-checkbox
                    v-model="assigned_team"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    :disabled="apptEngLoading"
                    multiple
                  > -->
                  <v-radio-group
                    v-model="assigned_team"
                    :label="team.display_name"
                    row
                    hide-details
                    :disabled="apptEngLoading"
                    class="ml-3 my-3"
                  >
                    <v-radio :value="team.id" color="cyan" class="mr-10">
                      <template v-slot:label>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(team.profile_logo)"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-size-16 font-weight-500"
                              v-html="`${team.team_name} (${team.barcode})`"
                            ></v-list-item-title>

                            <!--  <span class="text-capitalize">
                                <template v-if="team.skills">{{
                                  team.skills.join(", ")
                                }}</template>
                                <template v-else-if="team.designation">{{
                                  team.designation.value
                                }}</template>
                                <template v-else><em>No Skills</em></template>
                              </span> -->

                            <v-list-item-subtitle
                              v-if="lodash.isEmpty(team.designation) == false"
                              >{{
                                team.designation.value
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-list-item>
              </perfect-scrollbar>
              <v-btn
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-on:click="updateEngineer()"
                color="cyan white--text"
                class="w-100 text-uppercase font-weight-700 font-size-16 mb-1"
                >Save</v-btn
              >
            </v-list>
          </v-menu>
        </template>
        <v-btn
          class="mx-2 mb-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: calc(100vh - 110px); position: relative"
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="6">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr
                      v-if="
                        detail &&
                        detail.property &&
                        detail.property.property_address
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                          >{{ detail.property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.primary_phone
                          "
                        >
                          {{ detail.property_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <!-- <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template v-if="detail && detail.property_person && detail.property_person.primary_phone">
                          {{ detail.property_person.primary_phone }}
                        </template>

                      </td>
                    </tr> -->
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.primary_email
                          "
                        >
                          {{ detail.property_person.primary_email }}
                        </template>
                        <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">
                        Billing Address
                        <!-- <pre>{{serviceformdata.length}}</pre> -->
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail &&
                        detail.billing &&
                        detail.billing.property_address
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.display_name
                          "
                        >
                          {{ detail.billing_person.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.primary_phone
                          "
                        >
                          {{ detail.billing_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.primary_email
                          "
                        >
                          {{ detail.billing_person.primary_email }}
                        </template>
                        <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6" class="custom-border-left gray-background">
              <table width="100%">
                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    colspan="2"
                  >
                    <span
                      class="color-custom-blue font-weight-700 font-size-19"
                      >{{ ticket.title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Visit #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.barcode }}</label>
                  </td>
                </tr>
                <tr v-if="visit.contract_detail">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Contract #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">
                      <Barcode
                        route="contract.detail"
                        :barcode="visit.contract_detail.barcode"
                        :id="visit.contract_detail.id"
                      ></Barcode>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Reference #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.reference }}</label>
                  </td>
                </tr>
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    PO Number
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.po_number }}</label>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Payment Mode
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="
                      detail && detail.payment_mode && detail.payment_mode.text
                    "
                  >
                    <label class="m-0">{{ detail.payment_mode.text }}</label>
                  </td>
                </tr> -->

                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Customer Type
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                    visit.visit_customer_type
                    }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Quotation No
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.quotation }}</label>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Chargable
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500" v-if="runningAmount.total_charge">
                    <label class="m-0">{{ runningAmount.total_charge }}</label>
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600" v-else>
                    <em class="text--secondary">No chargable </em>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Customer Type
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500" v-if="visit.visit_dlp">
                    <label class="m-0">{{ visit.visit_dlp }}</label>
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600" v-else>
                    <em class="text--secondary">No Customer Type </em>
                  </td>
                </tr> -->
                <!--  <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Payment Mode
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="visit.payment_mode"
                  >
                    <label class="m-0">{{ visit.payment_mode }}</label>
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                    v-else
                  >
                    <em class="text--secondary">Not Selected</em>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Collected payment
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="visit.collected_payment"
                  >
                    <label class="m-0">{{ visit.collected_payment }}</label>
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                    v-else
                  >
                    <em class="text--secondary">Amount Not Received Yet</em>
                  </td>
                </tr> -->
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Appointment
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          v-bind="attrs"
                          v-on="on"
                          href="javascript:void(0)"
                          v-on:click="downloadVisit()"
                          class="text-uppercase max-content-width"
                          >{{ getAppointmentText }}</a
                        >
                      </template>
                      <span>Click here to download Appointment</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr v-if="detail.schedule_message">
                  <td valign="top" class="py-1 font-size-18 font-weight-600">
                    Schedule
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.schedule_message }}</label>
                  </td>
                </tr>
                <tr v-if="visit.cancel_reason">
                  <td valign="top" class="py-1 font-size-18 font-weight-600">
                    Cancel Reason
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.cancel_reason }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Tag
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-chip
                      v-for="(row, index) in detail.tags"
                      :key="index"
                      style="height: 26px"
                      class="mr-2"
                      color="cyan white--text"
                      >{{ row }}</v-chip
                    >
                  </td>
                </tr>
              </table>
              <v-tooltip
                v-for="(type, index) in detail.ticket_type"
                :key="'ticket-type' + index"
                top
                content-class="custom-top-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2 font-weight-600"
                    color="chip-custom-blue"
                    label
                    small
                    outlined
                  >
                    {{ type }}
                  </v-chip>
                </template>
                <span>Job Type</span>
              </v-tooltip>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="ticketTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab
                  v-if="follow_up_route == 'follow-up'"
                  class="font-size-16 font-weight-600 px-8"
                  href="#chatNotes"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Follow Up Notes
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Items
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#team">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Team
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#service-form"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/service-form.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Service Form
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#closer">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/closer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Closer
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#notes"
                  v-if="follow_up_route != 'follow-up'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/custom-svg/note.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  Notes
                </v-tab>
                <!--<v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                   
                  </span>
                  Invoices
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#quotation"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                 
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                   
                  </span>
                  Quotation
                </v-tab>-->
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#timestamp"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Timestamp
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#available-tickets"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                  </span>
                  Available Tickets
                </v-tab>
                <v-tab
                  v-if="visit.status == 4 && visit.follow_up == 1 && false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#followup"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                  </span>
                  Follow Up
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="ticketTab">
                <v-tab-item value="chatNotes">
                  <ChatNote
                    :relatedId="visitId"
                    :relatedType="'visit-notes'"
                    create-url="visit-follow-up-notes"
                    get-url="visit-follow-up-notes"
                    :documentTypes="25"
                    :detail="detail"
                    :visit="visit"
                  ></ChatNote>
                </v-tab-item>
                <v-tab-item value="other">
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Description</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.description || '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >REMARKS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-layout class="mb-4">
                          <v-flex md12 class="custom-border-right">
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.client_remark || '<em>No Remarks</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                          <!-- <v-flex md6>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.admin_remark || '<em>No Remarks</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex> -->
                        </v-layout>
                        <v-layout
                          v-if="detail.documents.length"
                          class="custom-border-top"
                        >
                          <v-flex md12>
                            <FileTemplate
                              :attachments="detail.documents"
                            ></FileTemplate>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="line-item">
                  <TicketLineItem
                    is-job
                    show-detail
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :adjustment-value="detail.adjustment"
                    :tax-value="detail.tax_value"
                    v-on:load:content="getVisit()"
                  ></TicketLineItem>
                </v-tab-item>
                <v-tab-item value="timestamp">
                  <VisitTimestamp
                    :detail="{ id: ticketId }"
                    :visit-id="visitId"
                    v-on:delete:engineer="getVisit()"
                  >
                  </VisitTimestamp>
                </v-tab-item>

                <v-tab-item value="followup">
                  <VisitfollowupDetail
                    :detail="detail"
                    :visit="visit"
                    :page-loading="visitLoading"
                  ></VisitfollowupDetail>
                </v-tab-item>
                <v-tab-item value="available-tickets">
                  <VisitListingTemplate
                    :customer-id="detail.customer.id"
                    detailType="tickets"
                    visitType="all"
                  ></VisitListingTemplate>
                </v-tab-item>
                <v-tab-item value="service-form">
                  <ServiceFormDetail
                    :detail="detail"
                    :visit="visit"
                    :accept_term="detail.accept_term"
                    :customer="detail.customer"
                    :property="detail.property"
                    :billing="detail.billing"
                    :contact-person="detail.property_person"
                    :page-loading="visitLoading"
                    is-visit-detail
                  >
                  </ServiceFormDetail>
                </v-tab-item>
                <v-tab-item value="closer">
                  <TicketCloserDetail
                    is-visit-detail
                    :visit-detail="visit"
                    :detail="detail"
                    :customer="detail.customer"
                    :property="detail.property"
                    :billing="detail.billing"
                    :contact-person="detail.property_person"
                    :page-loading="visitLoading"
                  ></TicketCloserDetail>
                </v-tab-item>
                <v-tab-item value="notes">
                  <VisitNotesDetail
                    v-if="false"
                    :detail="detail"
                    :visit="visit"
                    :notes="visit.notes"
                    :page-loading="visitLoading"
                  >
                  </VisitNotesDetail>
                  <ChatNote
                    :relatedId="visitId"
                    :relatedType="'visit'"
                    :documentTypes="26"
                    create-url="visit-notes"
                    get-url="visit-notes"
                    :detail="detail"
                    :visit="visit"
                  ></ChatNote>
                </v-tab-item>
                <!--  <v-tab-item value="invoice">
                  <InternalInvoice
                    type="visit"
                    :type-id="visitId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="quotation">
                  <InternalQuotation
                    type="visit"
                    :type-id="visitId"
                  ></InternalQuotation>
                </v-tab-item> -->
                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'history'"
                >
                  <InternalHistoryDetail
                    type="visit"
                    :type_id="visit.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
                <v-tab-item value="team">
                  <VisitTeamDetail
                    :detail="{ id: ticketId }"
                    :visit-id="visitId"
                    v-on:delete:engineer="getVisit()"
                  >
                  </VisitTeamDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <Dialog :common-dialog="startTimerDialog" v-if="false">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    Are you sure, you want to start timer for visit#
                    {{ visit.barcode }} ?
                  </p>
                </v-col>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimerWithLater"
          >
            Assign Later
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimer"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="startTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandStayandCheckoutDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue"></p>
                </v-col>

                <v-textarea
                  v-model.trim="data.remark"
                  auto-grow
                  dense
                  filled
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  label="Remarks"
                  solo
                  flat
                  :rules="[
                    validateRules.required(data.remark, 'Remarks'),
                    validateRules.minLength(data.remark, 'Remarks', 1),
                    validateRules.maxLength(data.remark, 'Remarks', 1024),
                  ]"
                  row-height="25"
                ></v-textarea>

                <!--  <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="HoldandStayDialog = true"
          >
            HOLD & STAY
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="HoldandCheckoutDialog = true"
          >
            HOLD & CHECKOUT
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandStayandCheckoutDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandStayDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    {{ visit.barcode }} will be on hold and all engineers stay
                    assigned to the job
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateOrCreateHold('stop_timer', 'hold')"
          >
            OK
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandStayDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandCheckoutDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    {{ visit.barcode }} will be on hold and all engineer will be
                    unassigned
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="
              updateOrCreateHoldandCheckout('stop_timer', 'checkouthold')
            "
          >
            OK
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandCheckoutDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="assignServiceFormDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="assignServiceForm"
            v-model.trim="assignFormValid"
            lazy-validation
            v-on:submit.stop.prevent="assignServiceForm"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="assignFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="assignFormLoading || !assignFormValid || visitLoading"
            :loading="assignFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="assignServiceForm"
          >
            Assign
          </v-btn>
          <v-btn
            :disabled="assignFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="assignServiceFormDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="resumeTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue my-0">
                  Are you sure, you want to resume timer for visit#
                  {{ visit.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            :loading="timerLoading || timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="resumeTimer()"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="resumeTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="remark_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to close ?
              </p>
              <v-layout class="my-4">
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    row-height="25"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="remark_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0" style="min-height: 27vh">
            <v-row>
              <!-- <v-col md="4" class="py-1 font-weight-600"> Date </v-col>
              <v-col md="4" class="py-1 font-weight-600"> Start Time </v-col>
              <v-col md="4" class="py-1 font-weight-600"> End Time </v-col> -->
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">Start Date</div>
                <Datepicker
                  :defaultDate="updateStartDate"
                  solo
                  v-on:update:date-picker="updateAppointmentDate"
                >
                </Datepicker>
              </v-col>
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">Start Time</div>
                <!-- <Timepicker
                  :defaultTime="updateStartTime"
                  solo
                  v-on:update:time-picker="updateAppointmentStartTime"
                ></Timepicker> -->
                <div class="time-picker-w-100 w-100">
                  <vue-timepicker
                    :minute-interval="15"
                    placeholder="Start Time"
                    input-width="17em"
                    :format="yourFormat"
                    v-model="starTimereschdule"
                    solo
                  >
                  </vue-timepicker>
                </div>
              </v-col>
              <!-- <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">End Date</div>
                <Datepicker
                  :defaultDate="updateEndDate"
                  solo
                  v-on:update:date-picker="updateAppointmentEndDate"
                >
                </Datepicker>
              </v-col> -->
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">End Time</div>
                <!-- <Timepicker
                  :defaultTime="updateEndTime"
                  solo
                  v-on:update:time-picker="updateAppointmentEndTime"
                ></Timepicker> -->
                <div class="time-picker-w-100 w-100">
                  <vue-timepicker
                    :minute-interval="15"
                    placeholder="End Time"
                    input-width="17em"
                    :format="yourFormat"
                    v-model="endTimereschdule"
                    solo
                  >
                  </vue-timepicker>
                </div>
              </v-col>

              <v-col md="12" class="py-2">
                <em
                  >All engineers will be removed from visit#
                  {{ visit.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment()"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="confirm_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p
                class="font-weight-600 font-size-19"
                v-if="!visit.contract_detail"
              >
                Are you sure, you want to
                <span class="font-weight-700">Cancel</span>?
              </p>
              <p v-else class="font-weight-600 font-size-19">
                You are cancel a contract job, do you want to reverse consume
                service back to contract?
              </p>
              <label for="cancel-reason" class="font-weight-600 font-size-18"
                >Reason</label
              >
              <v-textarea
                id="cancel-reason"
                auto-grow
                dense
                filled
                color="cyan"
                placeholder="Enter Reason..."
                solo
                flat
                v-model="cancel_reason"
                row-height="25"
              />
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="visit.contract_detail">
            <v-btn
              v-on:click="updateStatus('mark_as_cancel', false)"
              :disabled="!formValid || statusLoading"
              :loading="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Cancel Job Without Reverse
            </v-btn>
            <v-btn
              v-on:click="updateStatus('mark_as_cancel', true)"
              :disabled="!formValid || statusLoading"
              :loading="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Cancel Job & Reverse
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              v-on:click="updateStatus('mark_as_cancel')"
              :disabled="!formValid || statusLoading"
              :loading="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Yes! Continue
            </v-btn>
          </template>
          <v-btn
            :disabled="statusLoading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>

      <!--  <template v-if="team_dialog">
        <SelectTeam :team-dialog="team_dialog" v-on:resetAll="team_dialog = false" :visit-data="visit"
          :ticket-data="detail">
        </SelectTeam>
      </template> -->
      <CreateCloser
        v-if="runningTimer.id"
        is-visit-detail
        :ticketId="ticketId"
        :accept_term="detail.accept_term"
        :ticket-id="ticketId"
        :visit-id="visitId"
        :timesheet-id="runningTimer.id"
        :timesheet="runningTimer.id"
        :visit-detail="visit"
        :visit-payment="paymentmodes"
        :admin-email="company_email"
        :customer-mail="customer_email"
        :user-data="userArr.user_email"
        :user-detail="userArr"
        :closer-dialog="closerDialog"
        :detail="detail"
        v-on:create:ticket-closer="createdTimeCloser"
        v-on:close-dialog="closerDialog = false"
        :total-charge="runningAmount"
      ></CreateCloser>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="visit"
        v-on:close="mail_dialog = false"
        :type-id="visitId"
        v-on:success="getVisit()"
      ></SendMail>
      <ShareServiceReport
        :mail-dialog="share_dialog"
        title="Share Service Report"
        v-on:close="share_dialog = false"
        :visit-id="visitId"
        v-on:success="getVisit()"
        v-on:open="share_dialog = true"
      ></ShareServiceReport>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ChatNote from "@/view/pages/partials/ChatNote";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import TicketLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import ValidationMixin from "@/core/plugins/validation-mixin";
import VisitTeamDetail from "@/view/pages/job/partials/Visit-Team-Detail.vue";
import VisitfollowupDetail from "@/view/pages/job/partials/Visit-follow-up-Detail.vue";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import VisitTimestamp from "@/view/pages/job/partials/Visit-Timestamp.vue";
import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import TicketCloserDetail from "@/view/pages/job/partials/Ticket-Closer-Detail.vue";
import VisitNotesDetail from "@/view/pages/job/partials/Visit-Notes-Detail.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import ShareServiceReport from "@/view/pages/partials/Share-Service-Report-Mail";
//import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
//import Timepicker from "@/view/pages/partials/Timepicker.vue";
import Dialog from "@/view/pages/partials/Dialog";
//import SelectTeam from "@/view/pages/partials/Select-Team.vue";
//import InternalQuotation from "@/view/pages/partials/Internal-Quotation";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
//import {  CheckinCheckoutcata,} from "@/core/lib/checkin.lib";
import {
  GET,
  QUERY,
  PATCH,
  POST,
  PUT,
} from "@/core/services/store/request.module";
import Priority from "@/view/pages/partials/Priority.vue";
import CreateCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import JwtService from "@/core/services/jwt.service";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { ADD_CHECKOUT_VISIT } from "@/core/services/store/common.module";
import {
  ErrorEventBus,
  InitializeError,
  SuccessEventBus,
} from "@/core/lib/message.lib";
import {
  toSafeInteger,
  filter,
  head,
  last,
  isArray,
  isEmpty,
  concat,
  toNumber,
  find,
  forEach,
  toString,
  padStart,
} from "lodash";
import { downloadEvent } from "@/core/lib/ics.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { currentUser } from "@/core/services/jwt.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ObjectPath from "object-path";
//import CreateTicketCloserMixin from "@/core/lib/ticket-closer/create.ticket.closer.mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [
    CommonMixin,
    ValidationMixin,
    JobRequestMixin,
    // CreateTicketCloserMixin,
  ],
  data() {
    return {
      visitId: 0,
      yourFormat: "hh:mm A",
      ticketId: 0,
      share_dialog: false,
      cancel_reason: null,
      pageLoading: false,
      pageLoaded: false,
      statusLoading: false,
      confirm_loading: false,
      mail_dialog: false,
      visitLoading: false,
      serviceFormLoading: false,
      appointmentUpdateDialog: false,
      endTimereschdule: null,
      starTimereschdule: null,
      timerFormLoading: false,
      serviceFormList: [],
      startTimerDialog: false,
      follow_up_route: null,
      HoldandStayandCheckoutDialog: false,
      HoldandStayDialog: false,
      HoldandCheckoutDialog: false,
      timerLoading: false,
      team_dialog: false,
      remark_dialog: false,
      checkout: {},
      runningAmount: {
        service_form: null,
        ticket: null,
        visit: null,
        total_charge: 0,
      },
      data: {
        remark: null,
      },
      runningTimer: {
        barcode: null,
        closer: null,
        date: null,
        duration: null,
        end_at: null,
        end_date: null,
        id: null,
        is_service_form_assigned: false,
        is_service_form_filled: false,
        service_form: null,
        start_at: null,
        status: null,
        status_text: null,
        ticket: null,
        visit: null,
        total_charge: 0,
      },
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      isTimmerRunning: false,
      resumeTimerDialog: false,
      closerDialog: false,
      assignFormLoading: false,
      assignServiceFormDialog: false,
      timerFormValid: true,
      assignFormValid: true,
      timeInterval: null,
      durationInterval: null,
      timeIntervalLimit: 10000,
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_date: null,
        end_time: null,
      },
      apptFormLoading: false,
      apptEngLoading: false,
      engineer_menu: false,
      ticketTab: "chatNotes",
      timerServiceForm: [],
      serviceformdata: [],
      moreActions: [],
      detail: {
        term_conditions: null,
      },
      visit: {},
      paymentmodes: {},
      company_email: {},
      dbLineItems: [],
      dbEquipments: [],
      engineerList: [],
      assigned_team: [],
      closerdata: [],
      confirm_dialog: false,
    };
  },
  methods: {
    checkStartTimeButton() {
      const user = currentUser();
      // console.log(user, "user");
      if (this.visit.engineers) {
        let startTimerFormData = find(
          this.visit.engineers,
          (row) => row.user == user.id
        );
        // console.log(this.visit.engineers, "this.visit.engineers");

        //  console.log(startTimerFormData, "startTimerFormData")
        if (startTimerFormData) {
          return true;
        }
      }
      return false;
    },
    getFollowColor(data) {
      if (data == 1) {
        return "green";
      } else {
        return "red";
      }
    },
    VisitChecoutUpdate() {
      const _this = this;

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, { url: "checkout/" + this.visitId })
        .then(() => {})
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    /* getClosers() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "job/" + this.ticketId + "/complate-closers",
        })
        .then((response) => {
          this.closerdata = response.data;
        });
    }, */
    VisitCheckinUpdate() {
      const _this = this;

      _this.formLoading = true;

      _this.$store
        .dispatch(POST, { url: "checkin/" + this.visitId })
        .then((data) => {
          _this.getActiveTimer();
          this.checkIn = data;
          ServiceFormEventBus.$emit("reload:timestam-refresh", true);
          // _this.getActiveTimer();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          //   console.log(data, "data")
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                to_equipment: data[i].to_equipment,
                product_type: data[i].product_type,
                project_price: data[i].project_price,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
                is_optional: data[i].is_optional,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getDurationText() {
      const start_date = head(this.detail.duration);
      const last_date = last(this.detail.duration);
      return `${this.formatDate(start_date)} to ${this.formatDate(last_date)}`;
    },
    getServiceFormList() {
      if (this.serviceFormLoading) {
        return false;
      }
      this.serviceFormLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: { customer: this.visit.customer },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.timerServiceForm = this.lodash.map(
            this.serviceFormList,
            (row) => row.value
          );

          this.startTimerDialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.serviceFormLoading = false;
        });
    },
    startTimer() {
      // alert(223);
      /* if (!this.$refs.timerStartForm.validate()) {
        return false;
      } */

      this.timerFormLoading = true;
      this.timerLoading = true;

      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/timer/start",
          data: { service_form: this.timerServiceForm },
        })
        .then(() => {
          this.startTimerDialog = false;
          this.timerServiceForm = new Array();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.timerLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },

    updateOrCreateHold(action, type) {
      const _this = this;

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      const formData = {
        action,
        type,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.runningTimer.id,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        remark: _this.data.remark,

        tested_with_client: _this.data.tested_with_client,
      };
      _this.$store
        .dispatch(POST, {
          url: "job/" + _this.ticketId + "/visit/" + _this.visitId + "/hold",
          data: formData,
        })
        .then(() => {
          this.HoldandStayDialog = false;
          this.HoldandStayandCheckoutDialog = false;
          this.getVisit();
          this.getActiveTimer();
          // _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateOrCreateHoldandCheckout(action, type) {
      const _this = this;

      /*   if (!_this.$refs.ticketCloserForm.validate()) {
        return false;
      } */

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      const formData = {
        action,
        type,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.runningTimer.id,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        remark: _this.data.remark,

        tested_with_client: _this.data.tested_with_client,
      };
      _this.$store
        .dispatch(POST, {
          url:
            "job/" + _this.ticketId + "/visit/" + _this.visitId + "/checkout",
          data: formData,
        })
        .then(() => {
          this.HoldandCheckoutDialog = false;
          this.HoldandStayandCheckoutDialog = false;
          this.getVisit();
          this.getActiveTimer();
          // _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },

    startTimerWithLater() {
      if (this.isTimmerRunning) {
        this.closerDialog = true;
      } else {
        this.timerFormLoading = true;
        this.timerLoading = true;
        this.$store
          .dispatch(PATCH, {
            url:
              "job/" +
              this.ticketId +
              "/visit/" +
              this.visitId +
              "/timer/start",
          })
          .then(() => {
            this.startTimerDialog = false;
            this.timerServiceForm = new Array();
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerFormLoading = false;
            this.timerLoading = false;
            this.getVisit();
            this.checkTimerStatus();
            this.updateTimerDuration();
          });
      }
    },
    getActiveTimer() {
      if (this.visit.status == 2) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running/web",
          })
          .then(({ data }) => {
            this.runningTimer = new Object(data.result);
            this.checkout = new Object(data.checkOut);
            this.$store.dispatch(ADD_CHECKOUT_VISIT, this.checkout);

            //CheckinCheckoutcata.$emit("visit-newposts-code",this.checkout);
            // console.log(this.checkout, "this.checkout");
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
    closeFollowUp(data) {
      const _this = this;
      if (data.status == 2) {
        return false;
      }
      _this.$store
        .dispatch(PATCH, {
          url: "close-followup",
          data: {
            id: data.id,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getVisit();
        });
    },
    /*  getAmount() {
      if (this.visit.status != 1) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running-amount",
          })
          .then(({ data }) => {
            console.log(data,"data");
            this.runningAmount = new Object(data);
          })
          .catch((error) => {
            console.log(error,"error");
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
 */
    /*   getActiveTimer() {
      
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running",
          })
          .then(({ data }) => {
          
            this.runningTimer = new Object(data);
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      
    }, */

    /*  checkSignatureStatus(action) {
      if (action == "signature_link" || action == "send_as_email") {
        if (this.closerdata.accept_term == 1 || this.is_staff) {
          return true;
        }
        return false;
      }

      return true;
    }, */

    checkTimerStatus() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(
        _this.getActiveTimer,
        _this.timeIntervalLimit
      );
    },
    updateTimerDuration() {
      const _this = this;
      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    setTimerDuration() {
      if (isEmpty(this.runningTimer) === false) {
        let startDate = toString(this.runningTimer.date);
        if (this.runningTimer.start_at) {
          startDate = toString(startDate + " " + this.runningTimer.start_at);
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);

          let duration = moment.duration(moment().diff(startDate));

          let hours = parseInt(duration.asHours());
          let minutes = parseInt(duration.asMinutes()) % 60;
          let seconds = parseInt(duration.asSeconds()) % 60;

          this.timerDuration = {
            hour: hours > 0 ? padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? padStart(seconds, 2, "0") : "00",
          };
        }
      }
    },
    assignServiceFormDetail() {
      this.timerLoading = true;

      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            timesheet: this.runningTimer.id,
            visit: this.visitId,
            status: this.visit.status,
          },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.assignServiceFormDialog = true;
          this.timerServiceForm = this.lodash.map(
            this.serviceFormList,
            (row) => row.value
          );
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    assignServiceForm() {
      if (!this.$refs.assignServiceForm.validate()) {
        return false;
      }

      this.assignFormLoading = true;

      this.$store
        .dispatch(POST, {
          url: `job/${this.ticketId}/visit/${this.visitId}/service-form/${this.timerServiceForm}`,
          data: { timesheet: this.runningTimer.id || null },
        })
        .then(() => {
          this.timerServiceForm = new Array();
          ServiceFormEventBus.$emit("reload:service-form");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.assignFormLoading = false;
          this.assignServiceFormDialog = false;
          this.getActiveTimer();
        });
    },
    createdTimeCloser() {
      this.serviceFormLoading = false;
      this.timerFormLoading = false;
      this.serviceFormList = [];
      this.startTimerDialog = false;
      this.HoldandStayandCheckoutDialog = false;
      this.timerLoading = false;
      (this.userArr = null),
        (this.customer_email = null),
        (this.company_email = null),
        (this.runningTimer = {
          barcode: null,
          closer: null,
          date: null,
          duration: null,
          end_at: null,
          end_date: null,
          id: null,
          is_service_form_assigned: false,
          is_service_form_filled: false,
          service_form: null,
          start_at: null,
          status: null,
          status_text: null,
          ticket: null,
          visit: null,
        });
      this.timerDuration = {
        hour: "00",
        minute: "00",
        second: "00",
      };
      this.isTimmerRunning = false;
      this.resumeTimerDialog = false;
      this.closerDialog = false;
      this.assignFormLoading = false;
      this.assignServiceFormDialog = false;
      this.timerFormValid = true;
      this.assignFormValid = true;
      this.getVisit();
      clearInterval(this.timeInterval);
      clearInterval(this.durationInterval);
    },
    resumeTimer() {
      this.timerFormLoading = true;
      this.$store
        .dispatch(PATCH, { url: "visit/" + this.visitId + "/resume" })
        .then(() => {
          this.resumeTimerDialog = false;

          /* if (this.checkout && this.checkout.id == null) {
            this.VisitCheckinUpdate();
          } */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },
    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentEndDate(param) {
      this.updateAppointmentFormData.end_date = param;
    },
    updateAppointmentStartTime(param) {
      this.starTimereschdule = param;
    },
    updateAppointmentEndTime(param) {
      this.endTimereschdule = param;
    },
    updateAppointment() {
      this.apptFormLoading = true;
      /* console.log(this.starTimereschdule,"start time");
      console.log(this.starTimereschdule,"end time"); */
      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/re-schedule",
          data: {
            date: this.updateAppointmentFormData.date
              ? moment(this.updateAppointmentFormData.date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: this.updateAppointmentFormData.end_date
              ? moment(this.updateAppointmentFormData.end_date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),

            start_time: this.starTimereschdule
              ? moment(this.starTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
            end_time: this.endTimereschdule
              ? moment(this.endTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),

            /* start_time: this.starTimereschdule.start_time
              ? moment(this.starTimereschdule.start_time, [
                "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),

               end_time: this.endTimereschdule.end_time
              ? moment(this.endTimereschdule.end_time, [
                "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),   */
          },
        })
        .then(() => {
          this.appointmentUpdateDialog = false;
          this.$router.replace(this.getDefaultRoute("visit"));
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptFormLoading = false;
        });
    },
    getAvailableEngineers() {
      this.loadingEngineers = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/available-team-engineers",
          data: {
            start_date: moment(this.visit.started_at).format("YYYY-MM-DD"),
            start_time: moment(this.visit.started_at).format("HH:mm"),
            end_date: moment(this.visit.finished_at).format("YYYY-MM-DD"),
            end_time: moment(this.visit.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
            web: true,
          },
        })
        .then(({ data }) => {
          // console.log(data,"data");
          this.engineerList = data.engineers;
          // console.log(this.engineerList,"this.engineerList");
          this.engineer_menu = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },

    routeToDuplicate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            duplicate: this.visitId,
          },
        })
      );
    },
    updateEngineer() {
      //   console.log(this.assigned_team,"this.assigned_team");
      /* if (!this.assigned_team.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      } */

      let EngineerId = [];
      let teamData = find(
        this.engineerList,
        (row) => row.id == this.assigned_team
      );
      let teamId = teamData.id;
      // console.log(teamData.id,"teamData");
      if (teamData && teamData.team_data) {
        forEach(teamData.team_data, (row3) => {
          EngineerId.push(row3.staff_data.id);
        });
      }

      // console.log(EngineerId,"EngineerId");

      this.apptEngLoading = true;

      if (isArray(EngineerId) && EngineerId.length > 0) {
        this.$store
          .dispatch(PUT, {
            url:
              "job/" + this.ticketId + "/visit/" + this.visitId + "/engineer",
            data: {
              engineer: EngineerId,
              teamId: teamId,
            },
          })
          .then(() => {
            this.engineerList = [];
            SuccessEventBus.$emit(
              "update:success",
              this.visit.barcode + " Re-assign successfully."
            );
            this.$nextTick(() => {
              this.getVisit();
            });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.apptEngLoading = false;
          });
      }
    },
    updateMoreAction(type) {
      switch (type) {
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "convert_quotation":
          this.routeToQuotation();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "re_schedule_visit":
          this.appointmentUpdateDialog = true;
          break;
        case "mark_as_cancel":
          this.confirm_dialog = true;
          //this.updateStatus("mark_as_cancel");
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "available_staff":
          this.getAvailableEngineers();
          break;
        case "download_schedule":
          this.downloadSchedule();
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "visit/" + this.visitId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visit.started_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let startTimeArray = moment(this.visit.started_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        let endDateArray = moment(this.visit.finished_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let endTimeArray = moment(this.visit.finished_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        const visit = {
          start: concat(startDateArray, startTimeArray),
          end: concat(endDateArray, endTimeArray),
          title: this.ticket.title,
          description: this.visit.instruction,
          location: this.detail.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.detail.property.latitude),
            lon: toNumber(this.detail.property.longitude),
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.visit.added_by.display_name,
            email: this.visit.added_by.user_email,
          },
          attendees: this.visit.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        console.log({ error });
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    updateStatus(status, reverse_contract = false) {
      if (this.statusLoading) {
        return false;
      }
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "visit/" + this.visitId + "/status",
          data: { status, reverse_contract, cancel_reason: this.cancel_reason },
        })
        .then(() => {
          this.getVisit();
          this.confirm_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    downloadSchedule() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}job/${id}/download-schedule?token=${token}`;
      window.open(url, "_blank");
    },
    downloadPDF() {
      const { id } = this.visit;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}visit/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      window.open(this.visit.pdf_url, "_blank");
    },
    routeToQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },

    getVisit() {
      this.$store
        .dispatch(GET, { url: "visit/v1/" + this.visitId })
        .then(({ data }) => {
          this.starTimereschdule = moment(data.visit.started_at).format(
            "hh:mm A"
          );
          this.endTimereschdule = moment(data.visit.finished_at).format(
            "hh:mm A"
          );

          this.detail = data.ticket;
          this.company_email = data.company_email;
          this.customer_email = data.customer_email;
          this.userArr = data.userArr;
          this.visit_reschdule_count = data.visit_reschdule_count;
          this.visit = data.visit;
          this.ticket = data.ticket;
          this.paymentmodes = data.resultpayment_modes;
          if (this.detail.accept_term == 1) {
            this.detail.accept_term = true;
          }
          if (this.detail.accept_term == 0) {
            this.detail.accept_term = false;
          }

          this.ticketId = toSafeInteger(this.detail.id);
          //this.getClosers();
          this.visitId = toSafeInteger(this.visit.id);
          this.getServiceForms(this.ticketId);
          this.getActiveTimer();
          // this.getAmount();

          this.moreActions = data.visit.more_actions;

          if (this.visit.engineers && this.visit.engineers.length) {
            this.assigned_team = this.visit.engineers.map(
              (row) => row.engineer
            );
          }

          this.$nextTick(() => {
            this.getLineItems({
              job: this.ticketId,
              visit: this.visitId,
            });
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Visit", route: "visit" },
            { title: "Detail" },
            { title: this.visit.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.getActiveTimer();
        });
    },
    routeToUpdate() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            customer: ObjectPath.get(this.detail, "customer.id", null), // customer ? customer.id : null
            property: ObjectPath.get(this.detail, "property.id", null),
            "do-update": 1,
            "job-id": ObjectPath.get(this.detail, "id", null),
            "job-type":
              ObjectPath.get(this.detail, "type", 1) == 1
                ? "one-off"
                : "recurring",
          },
        })
      );
    },
  },
  created() {
    this.visitId = toSafeInteger(this.$route.params.id);
  },
  mounted() {
    this.getVisit();
    this.checkTimerStatus();
    this.updateTimerDuration();
    // this.getAmount();

    this.getActiveTimer();

    ServiceFormEventBus.$on("save:service-form", () => {
      this.getActiveTimer();
    });
    ServiceFormEventBus.$on("refresh", () => {
      // this.getAmount();
    });

    this.follow_up_route = this.$route.meta.menuActive;
    console.log(this.$route.meta.menuActive);
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.durationInterval);
  },
  computed: {
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
    is_staff_login() {
      const user = currentUser();
      const role_engineer = this.lodash.toLower(user.engineer);

      return role_engineer == role_engineer;
    },

    getAppointmentText() {
      if (isEmpty(this.visit) === false) {
        let date = this.formatDate(this.visit.started_at);
        let startTime = moment(this.visit.started_at).format("hh:mm A");
        let enddate = this.formatDate(this.visit.finished_at);
        let endTime = moment(this.visit.finished_at).format("hh:mm A");
        return `${date} ${startTime} - ${enddate} ${endTime}`;
      }
      return null;
    },
    updateStartDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateEndDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("hh:mm A");
      }
      return null;
    },
    getScheduleType() {
      if (this.detail.type === 1) {
        return "One-Off Job";
      }
      if (this.detail.type === 2) {
        return "Recurring Job";
      }
      return null;
    },
  },
  components: {
    Dialog,
    Priority,
    CreateCloser,
    CustomStatus,
    FileTemplate,
    DetailTemplate,
    Datepicker,
    Barcode,
    ChatNote,
    // Timepicker,
    /*  InternalInvoice,
    InternalQuotation, */
    TicketLineItem,
    VisitTeamDetail,
    VisitTimestamp,
    ServiceFormDetail,
    TicketCloserDetail,
    VisitNotesDetail,
    SendMail,
    ShareServiceReport,
    // SelectTeam,
    InternalHistoryDetail,
    VueTimepicker,
    VisitfollowupDetail,
    VisitListingTemplate,
  },
};
</script>
