<template>
  <v-container fluid>
    <v-form ref="visitNoteForm" v-model.trim="formValid" lazy-validation>
      <v-container fluid>
        <v-card
          flat
          class="custom-grey-border remove-border-radius"
          style="text-align: end"
        >
          <v-btn
            :disabled="dataLoading || UpdateFollowUp()"
            class="mx-2 custom-bold-button"
            v-on:click="manageBrandDialog = true"
            color="cyan white--text"
          >
            Update Follow Up
          </v-btn>

          <template v-if="manageBrandDialog">
            <Updatefollowup
              :c-dialog="manageBrandDialog"
              :detail="detail"
              v-on:close-dialog="manageBrandDialog = false"
              :visit="visit"
            ></Updatefollowup>
          </template>
        </v-card>
      </v-container>
    </v-form>
    <v-simple-table class="inner-simple-table custom-border-top" fixed-header>
      <template v-slot:default>
        <thead>
          <tr class="custom-border-bottom">
            <td class="font-size-16 font-weight-700" width="100">#</td>
            <td class="font-size-16 font-weight-700" width="200">
              Remark Date
            </td>
            <td class="font-size-16 font-weight-700" width="200">Remark</td>
            <td class="font-size-16 font-weight-700" width="200">
              Reminder Date
            </td>
            <td class="font-size-16 font-weight-700" width="200">By</td>
          </tr>
        </thead>
        <tbody>
          <template v-if="lodash.isEmpty(followup) === false">
            <template v-for="(row, index) in followup">
              <v-hover v-slot="{ hover }" :key="index">
                <tr
                  link
                  :key="'ticket-closer-row' + index"
                  :class="{
                    'ticket-closer-row-hover': hover,
                    'custom-border-bottom': followup[index + 1],
                  }"
                >
                  <td>
                    {{ index + 1 }}
                  </td>

                  <td class="font-size-16 font-weight-500">
                    <p
                      v-if="row.added_at"
                      class="m-0 custom-nowrap-ellipsis max-width-400px"
                    >
                      <span style="white-space: break-spaces">{{
                        formatDate(row.added_at)
                      }}</span>
                    </p>
                  </td>
                  <td class="font-size-16 font-weight-500">
                    <p
                      v-if="row.remark"
                      class="m-0 custom-nowrap-ellipsis max-width-400px"
                    >
                      <span style="white-space: break-spaces">{{
                        row.remark
                      }}</span>
                    </p>
                  </td>
                  <td class="font-size-16 font-weight-500">
                    <p
                      v-if="row.date"
                      class="m-0 custom-nowrap-ellipsis max-width-400px"
                    >
                      {{ formatDate(row.date) }}
                    </p>
                  </td>
                  <td class="font-size-16 font-weight-500">
                    <p
                      v-if="row && row.added_by && row.added_by.display_name"
                      class="m-0 custom-nowrap-ellipsis max-width-400px"
                    >
                      {{ row.added_by.display_name }}
                    </p>
                  </td>
                  <!-- <td class="font-size-16 font-weight-500  "> -->

                  <!-- <div
                v-if="row.followupdata.status != 2"
                class="btn-group btn-block btn-group-sm"
              >
                <v-tooltip top color="#000">
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="btn btn-primary updatebtn"
                      v-on:click="manageBrandDialog = true"
                      ><strong>Update</strong></a
                    >
                  </template>
                  <span>update status</span>
                </v-tooltip>
              </div> -->
                  <!-- <div v-if="row.followupdata.status == 2">
                <v-tooltip top color="#000">
                  <template v-slot:activator="{ on, attrs }"
                    ><span
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="openbtn ticketstatus"
                      >Closed</span
                    >
                  </template>
                  <span>Closed</span>
                </v-tooltip>
              </div> -->

                  <!--  </td> -->
                </tr>
              </v-hover>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <template v-if="manageBrandDialog">
      <Updatefollowup
        :c-dialog="manageBrandDialog"
        :detail="detail"
        v-on:close-dialog="manageBrandDialog = false"
        :visit="visit"
      ></Updatefollowup>
    </template>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

import { GET } from "@/core/services/store/request.module";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import Updatefollowup from "@/view/pages/job/partials/Update-follow-up.vue";
import { currentUser } from "@/core/services/jwt.service";
import { find } from "lodash";
export default {
  mixins: [CommonMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },

    invoice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      followup: null,
      visitNoteActive: true,
      manageBrandDialog: false,
      dataLoading: false,
      visitNote: null,
      visitNoteDocument: new Array(),
    };
  },
  components: {
    Updatefollowup,
  },
  methods: {
    UpdateFollowUp() {
      if (this.followup) {
        let followupstatus = find(
          this.followup,
          (row) => row.followupdata.status == 2
        );
        if (followupstatus) {
          return true;
        }
      }
      return false;
    },

    downloadFile(row) {
      window.open(row.file.url, "_blank");
    },

    getVisit(id) {
      const _this = this;
      let requestUrl = "job/" + _this.detail.id + "/visit/" + id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          _this.visitDetail = data;
          _this.visitNotes = data.notes;
          _this.visitDetailDialog = true;
          _this.visitDeleteRequestURL = requestUrl;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateFiles(param) {
      this.visitNoteDocument = param;
    },
    closeNote() {
      this.formValid = true;
      this.visitNote = null;
      this.visitNoteDocument = new Array();
      this.visitNoteActive = false;
    },

    getCertificate() {
      const _this = this;
      let requestUrl = "followup/" + this.visit.followup[0].id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          this.followup = data;
          //   console.log(this.followup,"this.followup");
          ServiceFormEventBus.$emit("reload:cetificate");
          ServiceFormEventBus.$emit(
            "reload:cetificatedata",
            this.cetificatedata
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },

  computed: {
    is_admin() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
  },
  mounted() {
    this.getCertificate();

    ServiceFormEventBus.$on("update:visitcetificate", () => {
      this.getCertificate();
    });

    ServiceFormEventBus.$on("reload:cetificate-data", () => {
      this.getCertificate();
    });
  },
};
</script>
